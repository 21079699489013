export const trackEvent = (event: string, params?: Record<string, unknown>) => {
  if (typeof analytics == 'undefined') return

  analytics.track(event, params)
}

export const trackEventOnGoogleAnalytics = (
  measurementId: string,
  eventCategory: string,
  eventLabel: string
) => {
  if (typeof window.gtag === 'undefined') return

  window.gtag('event', measurementId, {
    event_category: eventCategory,
    event_label: eventLabel,
  })
}
